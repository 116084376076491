import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >
<path d="M2310 4924 c-19 -2 -78 -9 -130 -15 -406 -47 -803 -211 -1165 -483
-95 -71 -340 -313 -416 -411 -226 -290 -369 -580 -454 -920 -56 -221 -69 -333
-69 -590 0 -252 11 -358 64 -575 60 -242 132 -422 269 -664 115 -204 354 -477
560 -644 333 -268 741 -454 1136 -516 255 -41 459 -43 715 -10 349 45 704 180
1005 380 386 257 658 565 855 969 172 352 243 658 243 1055 0 296 -42 555
-133 815 -76 218 -239 507 -395 706 -85 108 -316 333 -425 415 -333 249 -713
409 -1121 469 -98 15 -464 28 -539 19z m335 -144 c442 -30 867 -188 1225 -454
125 -93 358 -326 451 -451 73 -98 86 -119 157 -240 193 -332 302 -738 302
-1128 0 -850 -465 -1622 -1221 -2026 -231 -123 -538 -217 -819 -251 -110 -13
-382 -13 -490 0 -478 59 -900 245 -1252 552 -458 400 -732 949 -779 1565 -40
511 116 1060 421 1481 25 34 57 80 72 101 28 38 247 264 302 311 379 321 887
521 1376 543 36 2 74 4 85 5 11 1 88 -3 170 -8z"/>
<path d="M2305 4754 c-209 -22 -454 -79 -621 -145 -667 -264 -1150 -790 -1354
-1473 -67 -225 -85 -363 -85 -636 1 -266 19 -408 81 -621 135 -466 427 -892
806 -1177 89 -67 325 -214 373 -232 11 -4 41 -17 65 -28 323 -147 730 -221
1068 -194 250 20 439 60 647 137 181 68 389 178 525 279 30 23 62 46 71 51 56
33 310 283 379 374 14 18 35 45 46 59 63 80 156 235 229 382 160 324 244 755
216 1107 -27 339 -96 596 -235 874 -181 361 -436 649 -776 876 -67 45 -102 66
-215 127 -173 93 -428 176 -665 216 -121 21 -449 35 -555 24z m-911 -710 c11
-4 16 -19 16 -48 l0 -41 -18 30 c-27 43 -47 57 -79 53 -24 -2 -28 -8 -28 -32
0 -26 5 -31 55 -48 30 -10 63 -28 73 -39 22 -27 22 -81 -1 -109 -21 -26 -96
-37 -152 -23 -32 8 -35 12 -38 52 l-3 43 44 -46 c45 -47 80 -59 105 -34 28 28
13 48 -63 83 -58 26 -76 39 -81 59 -10 39 3 74 34 90 27 15 107 21 136 10z
m168 -6 c-8 -8 -12 -46 -12 -105 0 -82 2 -94 22 -113 28 -25 52 -26 78 0 17
17 20 33 20 106 0 47 -5 94 -10 105 -10 17 -7 19 20 19 29 0 40 -13 20 -25 -5
-3 -10 -45 -10 -92 0 -122 -21 -152 -107 -153 -45 0 -80 15 -95 42 -7 13 -15
69 -18 126 l-5 102 55 0 c42 0 51 -3 42 -12z m374 -10 c23 -23 21 -69 -5 -86
l-21 -14 25 -20 c18 -14 25 -29 25 -53 0 -51 -32 -68 -139 -73 l-86 -4 0 137
0 137 92 -4 c69 -2 96 -7 109 -20z m291 0 c40 -37 28 -79 -40 -140 l-42 -37
46 -1 c25 0 50 5 57 12 20 20 20 5 1 -39 l-18 -43 -76 0 c-41 0 -75 4 -75 9 0
6 23 36 50 67 51 58 63 97 38 122 -7 7 -24 12 -38 12 -14 0 -31 -5 -38 -12 -9
-9 -12 -8 -12 5 0 10 11 29 25 42 32 33 89 34 122 3z m272 -40 c15 -35 32 -74
38 -88 l10 -25 21 40 c32 60 26 36 -8 -37 -16 -34 -30 -69 -30 -75 0 -7 -4
-13 -9 -13 -7 0 -35 57 -74 150 -8 20 -20 43 -28 51 -8 8 -20 25 -27 37 -12
21 -11 22 33 22 l46 0 28 -62z m196 -68 l0 -130 -40 0 -40 0 3 107 c2 81 0
104 -8 93 -9 -12 -10 -10 -4 10 10 38 24 50 58 50 l31 0 0 -130z m249 114 c3
-9 6 -25 6 -36 0 -17 -4 -15 -26 11 -19 24 -34 31 -60 31 l-34 0 0 -60 c0 -57
1 -60 24 -60 14 0 32 7 40 16 15 14 16 12 16 -23 -1 -31 -3 -35 -11 -20 -6 10
-22 17 -40 17 l-31 0 4 -60 3 -60 -40 0 -40 0 0 130 0 130 91 0 c75 0 93 -3
98 -16z m301 6 c58 -13 95 -58 95 -115 0 -24 -5 -54 -11 -67 -13 -31 -69 -68
-100 -68 -22 1 -22 2 -6 11 63 37 59 210 -5 233 -52 19 -58 7 -58 -118 0 -82
4 -116 13 -119 6 -3 -9 -6 -34 -6 l-46 -1 5 99 c3 58 0 112 -6 130 l-11 31 62
0 c34 0 80 -5 102 -10z m288 -82 c19 -51 42 -110 51 -130 l16 -38 -50 0 c-48
0 -50 1 -50 28 0 45 -12 54 -66 50 l-49 -3 0 -38 c0 -32 -3 -37 -23 -37 l-22
0 21 42 c12 23 29 65 39 93 38 106 49 125 74 125 20 0 28 -12 59 -92z m307 75
c0 -10 3 -29 6 -43 5 -25 5 -25 -11 2 -17 30 -44 48 -70 48 -15 0 -16 -14 -13
-125 l3 -125 -40 0 -40 0 3 125 c3 118 3 125 -16 125 -10 0 -31 -11 -46 -25
l-26 -24 0 29 0 30 125 0 c112 0 125 -2 125 -17z m185 -85 c21 -57 44 -112 51
-123 22 -31 17 -35 -36 -35 -48 0 -50 1 -50 28 0 45 -12 54 -66 50 l-49 -3 0
-38 c0 -31 -3 -37 -22 -37 -22 0 -21 1 9 68 34 76 43 89 33 49 -7 -26 -6 -27
34 -27 47 0 49 6 20 70 -16 35 -22 41 -29 30 -14 -22 -12 -4 4 35 11 26 21 35
39 35 22 0 29 -11 62 -102z m-1069 -133 c-5 -21 -12 -25 -44 -24 -34 0 -35 1
-13 10 14 5 31 19 39 30 18 25 26 18 18 -16z m-532 -1 c9 -23 8 -24 -13 -24
-14 0 -18 5 -14 20 6 24 19 26 27 4z m-142 -182 c-7 -17 -42 -67 -185 -262
l-58 -79 -489 -3 c-546 -3 -517 1 -527 -74 -7 -53 18 -83 77 -95 25 -4 228 -8
452 -9 419 0 487 -5 579 -44 45 -19 50 -31 37 -87 -19 -79 -48 -129 -108 -191
-47 -47 -66 -60 -92 -61 -30 -2 -33 1 -36 28 -2 17 -13 40 -25 53 l-20 22
-398 0 c-473 0 -558 8 -680 66 -63 31 -142 120 -164 186 -24 70 -25 215 -3
286 22 67 70 135 128 177 53 39 173 84 250 95 30 4 328 8 662 9 585 1 607 0
600 -17z m438 -75 c79 -31 103 -52 129 -113 5 -11 15 -27 23 -35 7 -8 27 -40
43 -73 45 -88 68 -129 90 -161 12 -17 28 -46 38 -65 10 -19 33 -62 51 -95 37
-64 76 -136 123 -225 16 -30 35 -55 42 -55 11 0 40 48 141 240 17 33 49 89 70
125 21 36 48 85 60 110 12 25 28 52 35 60 11 14 57 94 92 162 21 41 85 102
128 123 48 22 128 31 158 17 12 -5 38 -17 57 -25 42 -19 98 -79 106 -116 4
-14 10 -30 14 -36 4 -5 10 -21 13 -35 9 -42 63 -233 137 -490 39 -135 75 -261
80 -280 5 -19 27 -102 49 -184 23 -82 39 -157 36 -167 -6 -18 -20 -19 -195
-19 -204 0 -220 4 -220 55 0 13 -4 26 -9 29 -5 3 -11 20 -14 38 -5 31 -13 65
-37 161 -6 20 -14 54 -19 75 -20 80 -44 168 -51 187 -11 28 -27 91 -41 155
-16 77 -18 82 -27 76 -4 -2 -46 -80 -92 -173 -47 -92 -88 -170 -91 -173 -4 -3
-20 -32 -36 -65 -28 -57 -46 -90 -122 -226 -63 -115 -175 -161 -306 -128 -50
13 -122 70 -145 115 -8 16 -30 56 -49 89 -30 54 -142 267 -187 355 -9 19 -21
42 -26 50 -4 8 -20 38 -34 65 -15 28 -30 52 -34 55 -4 3 -9 12 -12 20 -5 17
-38 -38 -38 -65 0 -8 -4 -23 -10 -33 -5 -9 -21 -61 -35 -114 -42 -159 -98
-262 -188 -345 -81 -74 -201 -136 -332 -172 -99 -27 -209 -31 -809 -31 l-626
0 0 24 c0 13 15 43 33 67 18 24 39 54 47 65 13 19 63 85 141 187 l21 27 507 0
506 0 75 25 c160 51 178 62 210 125 27 53 160 472 160 503 0 9 4 25 10 35 5 9
19 51 31 92 12 41 35 94 52 117 17 24 31 43 31 43 1 0 28 11 61 25 73 30 113
31 185 2z m-483 -649 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m624 -642 c175 -53 272 -175 271 -341 0 -104 -54 -216 -151 -315 -37 -38 -228
-199 -294 -250 -32 -23 -57 -46 -57 -51 0 -5 87 -9 193 -9 238 0 279 9 350 80
26 26 47 44 47 40 0 -3 -29 -88 -64 -187 -35 -100 -67 -195 -71 -212 l-7 -31
-400 0 c-241 0 -398 4 -396 9 3 9 55 66 334 363 153 162 214 273 214 386 0
181 -169 287 -356 226 -54 -18 -126 -80 -145 -126 -17 -41 -33 -35 -25 10 8
52 40 152 64 198 48 94 158 182 262 210 61 17 175 17 231 0z"/>
<path d="M1820 3984 c0 -47 3 -55 17 -52 10 2 24 15 32 29 13 24 13 29 -2 53
-30 45 -47 34 -47 -30z"/>
<path d="M1820 3860 c0 -51 3 -60 18 -60 31 0 44 17 45 55 1 26 -5 42 -18 51
-36 27 -45 18 -45 -46z"/>
<path d="M3416 3943 c-9 -26 -16 -50 -16 -55 0 -4 18 -8 41 -8 37 0 40 2 33
23 -11 37 -33 87 -38 87 -2 0 -11 -21 -20 -47z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
